<template>
  <div>
    <v-card-actions id="frmListData">
      <v-card width="100%">
        <v-card-actions>
          <v-spacer />
          <v-text-field
            id="edtFilter"
            v-model="fFilter"
            append-icon="mdi-filter"
            label="Filtrar Dados Recuperados"
            single-line
            hide-details
            class="body-2"
          />
        </v-card-actions>
        <v-card-actions>
          <div
            class="caption warning--text"
            v-if="fOriginOfRecords == fOriginPanelSearch"
          >
            <v-icon class="caption warning--text">mdi-alert</v-icon> Recuperados
            apenas dados resultado da PESQUISA, clique em ATUALIZAR para listar
            todos os registros
          </div>
        </v-card-actions>
        <v-divider />
        <v-data-table
          id="frmList"
          width="984"
          :headers="fHeaders"
          :items="fListOfRecords"
          :search="fFilter"
          :dense="true"
          :loading="fLoading"
          loading-text="Aguarde Recuperando os dados"
          class="elevation-1"
          :calculate-widths="true"
          :footer-props="{
            itemsPerPageOptions: [8],
            showFirstLastPage: true,
            firstIcon: 'mdi-skip-previous-circle',
            lastIcon: 'mdi-skip-next-circle',
            prevIcon: 'mdi-arrow-left-drop-circle',
            nextIcon: 'mdi-arrow-right-drop-circle'
          }"
        >
          <template #item.actions="{item}">
            <v-btn icon x-small @click="onGoDetailRecord(item)">
              <!-- v-icon class="info">mdi-account-card-details</v-icon -->
              <v-icon>mdi-account-box</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-card-actions>
  </div>
</template>

<script>
import { cOriginPanelSearch } from '@/services/consts'

export default {
  name: 'ListData',

  props: {
    //fFilter: String,
    fHeaders: Array,
    fListOfRecords: Array,
    fOriginOfRecords: Number,
    fLoading: {
      type: Boolean,
      default: false
    },
    fTypeUser: {
      type: Number,
      default: 1
    }
  },

  mounted() {
    var divs = document.getElementsByTagName('div')
    for (var i = 0; i <= divs.length; i++) {
      //if (divs[i].className) {
      //if (divs[i].className == 'v-data-footer__pagination') {
      if (divs[i].classList.contains('v-data-footer__pagination')) {
        divs[i].style.display = 'none'
      }
      //}
    }
  },

  data() {
    return {
      fOriginPanelSearch: cOriginPanelSearch,
      fFilter: ''
    }
  },

  methods: {
    onGoDetailRecord(aRecord) {
      this.$emit('onGoDetailRecordOwner', aRecord)
    }
  }
}
</script>

<!--style>
.v-data-footer__pagination {
  display: none;
}
</style-->
