<template>
  <!-- FORM DIALOG -->
  <v-dialog
    v-model="fShowDialogData"
    max-width="80%"
    max-height="80%"
    class="elevation-1"
    persistent
    scrollable
  >
    <v-card width="100%">
      <v-card-title class="info white--text">
        Associado Online
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form>
          <v-row>
            <v-col col="9" sm="3" md="3">
              <v-select
                v-model="fRecord.cd_tipoassociacao"
                :items="fTipoAssociacao"
                menu-props="auto"
                label="Categoria Associativa"
                hide-details
                item-value="value"
                item-text="text"
                @change="onDataChanged"
                @keydown="onDataChanged"
              ></v-select>
            </v-col>
            <v-col col="9" sm="7" md="7">
              <v-text-field
                label="Status"
                v-model="fStatusAssociado"
                type="text"
                color="blue"
                readonly
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
              <!--span class="info--text">
                {{ fStatusAssociado }}
              </span-->
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="10" md="10">
              <v-text-field
                label="Nome"
                v-model="fRecord.nm_nome"
                type="text"
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="2" md="2">
              <v-text-field
                label="CPF"
                v-model="fRecord.nr_cpf"
                type="text"
                maxlength="11"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>

            <v-col cols="9" sm="3" md="3">
              <v-text-field
                v-model="fRecord.dt_nascimento"
                label="Data de Nascimento"
                :readonly="fTypeUser != fTypeUserADMAPP"
                prepend-icon="mdi-calendar"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="5" md="5">
              <v-text-field
                label="E-mail Principal"
                v-model="fRecord.nm_email"
                type="text"
                maxlength="100"
                onpaste="return false"
                ondrop="return false"
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>

            <v-col cols="9" sm="5" md="5">
              <v-text-field
                label="E-mail Secundário"
                v-model="fRecord.nm_email2"
                type="text"
                maxlength="100"
                onpaste="return false"
                ondrop="return false"
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="4" md="4">
              <v-text-field
                label="Telefone Celular"
                v-model="fRecord.nm_telefonecelular"
                type="text"
                maxlength="30"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>

            <v-col cols="9" sm="3" md="3">
              <v-text-field
                label="Telefone Comercial"
                v-model="fRecord.nm_telefonecomercial"
                type="text"
                maxlength="30"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>

            <v-col cols="9" sm="3" md="3">
              <v-text-field
                label="Telefone Residencial"
                v-model="fRecord.nm_telefoneresidencial"
                type="text"
                maxlength="30"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="10" md="10">
              <v-text-field
                label="Nome da Mãe"
                v-model="fRecord.nm_mae"
                type="text"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="10" md="10">
              <v-text-field
                label="Endereço"
                v-model="fRecord.nm_endereco"
                type="text"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="2" md="2">
              <v-text-field
                label="Numero"
                v-model="fRecord.nr_numero"
                type="text"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>

            <v-col cols="9" sm="4" md="4">
              <v-text-field
                label="Complemento"
                v-model="fRecord.nm_complemento"
                type="text"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>

            <v-col cols="9" sm="4" md="4">
              <v-text-field
                label="Bairro"
                v-model="fRecord.nm_bairro"
                type="text"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="6" md="6">
              <v-text-field
                label="Cidade"
                v-model="fRecord.nm_cidade"
                type="text"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>

            <v-col col="9" sm="2" md="2">
              <v-select
                v-model="fRecord.cd_uf"
                :items="fUFs"
                menu-props="auto"
                label="UF"
                :readonly="fTypeUser != fTypeUserADMAPP"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="9" sm="2" md="2">
              <v-text-field
                label="CEP"
                v-model="fRecord.nr_cep"
                type="text"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>
          </v-row>

          <v-row v-if="fRecord.cd_tipoassociacao === 2">
            <v-col cols="9" sm="10" md="10">
              <v-text-field
                label="Associado Vinculado"
                v-model="fRecord.nm_nomeassociadovinculado"
                type="text"
                :readonly="fTypeUser != fTypeUserADMAPP"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="9" sm="6" md="6">
              <v-select
                :label="fLabelCategoriaProfissional"
                v-model="fRecord.nm_profissao"
                :items="fCategoriasProfissionais"
                menu-props="auto"
                hide-details
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
            <v-col
              cols="9"
              sm="4"
              md="4"
              v-if="fRecord.cd_tipoassociacao === 2"
            >
              <v-select
                label="Parentesco"
                v-model="fRecord.cd_parentesco"
                :items="fParentesco"
                :readonly="fTypeUser != fTypeUserADMAPP"
                menu-props="auto"
                hide-details
                item-value="value"
                item-text="text"
                v-if="fRecord.cd_tipoassociacao === 2"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <ButtonsDialogData
        :fInInsert="fInInsert"
        :fInEdit="fInEdit"
        :fDataChanged="fDataChanged"
        :fShowButtonNewData="false"
        :fShowButtonDelete="false"
        :fShowBtnCustom01="false"
        :fCaptionBtnCustom01="fCaptionBtnCustom01"
        :fIconBtnCustom01="fIconBtnCustom01"
        :fShowMenu="true"
        :fMenuItems="fMenuItems"
        @onClickMenuOwner="onClickMenu"
        @onEventBtnCustom01Owner="onExecuteBtnCustom"
        @onSaveRecordOwner="onSaveRecord"
        @onDeleteRecordOwner="onDeleteRecord"
        @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
        @onNewDataOwner="onNewData"
        @onCancelRecordChangedOwner="onCancelRecordChanged"
      />

      <DialogConfirmSimple
        v-if="fShowDialogConfirmReenvioEmail"
        :fShowDialogConfirmSimple="fShowDialogConfirmReenvioEmail"
        fTextTitleDialog="Reenvio E-mail Acesso Area Associado "
        fTextDialog="Confirma Reenvio E-mail Acesso Area Associado?"
        @onResultYesOwner="onSendLinkEmailAreaAssociado"
        @onResultNoOwner="onResultNoSendLinkEmailAreaAssociado"
        fMaxWidth="450"
      />

      <DialogConfirmSimple
        v-if="fShowDialogConfirmGeneratePDFAssociado"
        :fShowDialogConfirmSimple="fShowDialogConfirmGeneratePDFAssociado"
        fTextTitleDialog="Gerar Declaração do Associado"
        fTextDialog="Confirma Geração Declaração do Associado?"
        @onResultYesOwner="onGeneratePDFAssociado"
        @onResultNoOwner="onResultNoGeneratePDFAssociado"
        fMaxWidth="450"
      />
      <!-- @onEventBtnCustom01Owner="onSendLinkEmailAreaAssociado" -->
      <!-- /v-card-actions -->
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonsDialogData from '@/components/ButtonsDialogData.vue'
import DialogConfirmSimple from '@/components/DialogConfirmSimple.vue'
import {
  cTrueStrChar,
  cFalseStrChar,
  cClienteStr,
  ADMAPP
} from '@/services/consts'
import { _ConsoleLog } from '../../../services/functions/view'
import { mapGetters } from 'vuex'

export default {
  name: 'CadastroDialogData',

  components: {
    ButtonsDialogData,
    DialogConfirmSimple
  },

  props: {
    fShowDialogData: Boolean,
    fRecord: Object,
    fInInsert: Boolean,
    fInEdit: Boolean,
    fDataChanged: Boolean
  },

  data() {
    return {
      fTypeUserADMAPP: ADMAPP,
      fShowDialogConfirmReenvioEmail: false,
      fShowDialogConfirmGeneratePDFAssociado: false,
      fMenuItems: [
        {
          Title: 'Reenviar E-mail Acesso Area Associado',
          Action: 'Send-Email',
          Icon: 'mdi-send'
        },
        {
          Title: 'Gerar Declaração do Associado',
          Action: 'Generate-Declaraction',
          Icon: 'mdi-send'
        },
        {
          Title: 'Enviar E-mail com Declaração de Associado Cancelado',
          Action: 'Send-Email-Declaracao-Cancelamento',
          Icon: 'mdi-send'
        }
      ],

      fMenuItemsAtivo: [
        {
          Title: 'Reenviar E-mail Acesso Area Associado',
          Action: 'Send-Email',
          Icon: 'mdi-send'
        },
        {
          Title: 'Gerar Declaração do Associado',
          Action: 'Generate-Declaraction',
          Icon: 'mdi-send'
        }
      ],

      fMenuItemsCancelado: [
        {
          Title: 'Enviar E-mail com Declaração de Associado Cancelado',
          Action: 'Send-Email-Declaracao-Cancelamento',
          Icon: 'mdi-send'
        }
      ],

      rules: {
        email: value => {
          var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (re.test(value)) {
            return true
          } else {
            return 'E-mail inválido'
          }
        }
      },

      fCaptionBtnCustom01: 'Reenviar E-mail Acesso Area Associado',

      fMenu: false,

      fEmailLogin: { Email: '' },

      fTipoAssociacao: [
        { text: 'Sócio Efetivo', value: 1 },
        { text: 'Sócio Vinculado', value: 2 }
      ],

      fParentesco: [
        { text: 'MARIDO', value: 1 },
        { text: 'ESPOSA', value: 2 },
        { text: 'FILHO', value: 3 },
        { text: 'FILHA', value: 4 },
        { text: 'PAI', value: 5 },
        { text: 'MÃE', value: 6 },
        { text: 'NETO', value: 7 },
        { text: 'NETA', value: 8 },
        { text: 'GENRO', value: 9 },
        { text: 'NORA', value: 10 },
        { text: 'COMPANHEIRO', value: 14 },
        { text: 'COMPANHEIRA', value: 15 },
        { text: 'IRMÃO', value: 16 },
        { text: 'IRMÃ', value: 17 },
        { text: 'ENTEADO', value: 21 },
        { text: 'ENTEADA', value: 22 }
      ],

      fUFs: [
        'AC',
        'AL',
        'AM',
        'AP',
        'BA',
        'CE',
        'DF',
        'ES',
        'GO',
        'MA',
        'MG',
        'MS',
        'MT',
        'PA',
        'PB',
        'PE',
        'PI',
        'PR',
        'RJ',
        'RN',
        'RO',
        'RR',
        'RS',
        'SC',
        'SE',
        'SP',
        'TO'
      ],

      fCategoriasProfissionais: [
        'Administradores',
        'Arquitetos ',
        'Assistentes Sociais ',
        'Atuários ',
        'Arquivologistas',
        'Automação Industrial ',
        'Bacharéis em Direito ',
        'Biblioteconomia ',
        'Biomédicos ',
        'Biólogos',
        'Ciências Aeronáuticas ',
        'Cinema ',
        'Comércio Exterior ',
        'Contabilista ',
        'Dentista ',
        'Desenho Industrial ',
        'Designers de Interiores ',
        'Designers de Moda ',
        'Designers Gráficos ',
        'Economistas',
        'Educação Física ',
        'Enfermeiros ',
        'Engenheiros',
        'Estatisticos',
        'Farmacêuticos',
        'Filósofos',
        'Físicos',
        'Fisioterapeutas',
        'Fonoaudiólogos',
        'Fotógrafos',
        'Gastronomia',
        'Geografia',
        'Geologia',
        'Gestão Ambiental',
        'Gestão Comercial',
        'Gestão de Tecnologia da Informação',
        'Gestão de Recursos Humanos',
        'Gestão de Segurança Privada',
        'Gestão de Seguros',
        'Gestão de Turismo',
        'Gestão Financeira',
        'Gestão Hospitalar',
        'Gestão Pública',
        'Hotelaria',
        'Historiadores',
        'Jornalistas',
        'Letras',
        'Logísticas',
        'Matemáticos',
        'Médicos',
        'Negócios',
        'Imobiliários',
        'Nutricionistas',
        'Pedagogos',
        'Professores de ensino médio e curso superior',
        'Profissionais de Informática',
        'Psicólogos',
        'Publicitários',
        'Químicos',
        'Radiologistas',
        'Redes de Telecomunicações',
        'Relações Internacionais',
        'Secretariados',
        'Segurança do Trabalho',
        'Sociólogos',
        'Técnicos em Contabilidade',
        'Técnicos em Enfermagem',
        'Técnicos em Laboratórios',
        'Teólogos',
        'Turismo e Veterinários'
      ]
    }
  },

  mounted() {
    _ConsoleLog('TypeUser: ' + this.fTypeUser)
    _ConsoleLog('TypeUser: ' + this.fTypeUserADMAPP)
  },

  computed: {
    ...mapGetters({
      fTypeUser: 'TypeUser'
    }),
    fAssociadoAtivo: () => {
      return this._AssociadoAtivo()
    },
    fIconBtnCustom01: () => {
      return 'mdi-menu'
    },
    fStatusAssociado: function() {
      if (this.fRecord.fl_confirmacaocancelamentosys == cTrueStrChar)
        return (
          'Cancelamento Confirmado pela ' +
          cClienteStr +
          ' em ' +
          this.fRecord.dthr_confirmacaocancelamentosysstr
        )
      else if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar)
        return (
          'Cancelamento Confirmado pelo Cliente em ' +
          this.fRecord.dthr_confirmacaocancelamentostr
        )
      else if (this.fRecord.fl_solicitacaocancelamento == cTrueStrChar)
        return (
          'Cancelamento Solicitado pelo Cliente em ' +
          this.fRecord.dthr_solicitacaocancelamentostr
        )
      else return 'Associado em ' + this.fRecord.dthr_insertstr
    },
    fLabelCategoriaProfissional: function() {
      if (this.fRecord.cd_tipoassociacao == 1) {
        return 'Categoria Profissional'
      } else {
        return 'Categoria Profissional do Associado Vinculado'
      }
    }
  },

  methods: {
    _AssociadoAtivo() {
      return (
        this.fRecord.fl_confirmacaocancelamentosys == cFalseStrChar &&
        this.fRecord.fl_confirmacaocancelamento == cFalseStrChar &&
        this.fRecord.fl_solicitacaocancelamento == cFalseStrChar
      )
    },

    onNewData() {
      this.$emit('onNewDataOwner')
    },

    onDataChanged() {
      this.$emit('onDataChangedOwner')
    },

    onCancelRecordChanged() {
      this.$emit('onCancelRecordChangedOwner')
    },

    onSaveRecord() {
      this.$emit('onSaveRecordOwner')
    },

    onDeleteRecord() {
      this.$emit('onDeleteRecordOwner')
    },

    onCancelAndBackToListData() {
      this.$emit('onCancelAndBackToListDataOwner')
    },

    onGoItemCadastro() {
      this.$emit('onGoItemCadastroOwner')
    },

    onExecuteBtnCustom() {
      this.onSendLinkEmailAreaAssociado()
    },

    onSendLinkEmailAreaAssociado() {
      this.$emit('onSendLinkEmailAreaAssociadoOwner')
      this.fShowDialogConfirmReenvioEmail = false
    },

    onResultNoSendLinkEmailAreaAssociado() {
      this.fShowDialogConfirmReenvioEmail = false
    },

    onGeneratePDFAssociado() {
      this.$emit('onGeneratePDFAssociadoOwner')
      this.fShowDialogConfirmGeneratePDFAssociado = false
    },

    onResultNoGeneratePDFAssociado() {
      this.fShowDialogConfirmGeneratePDFAssociado = false
    },

    onSendEmailDeclaracaoCancelamento() {
      this.$emit('onSendEmailDeclaracaoCancelamentoOwner')
    },

    onClickMenu(aAction) {
      switch (aAction) {
        case 'Send-Email':
          //this.onSendLinkEmailAreaAssociado()
          this.fShowDialogConfirmReenvioEmail = true
          break
        case 'Generate-Declaraction':
          //this.onGeneratePDFAssociado()
          this.fShowDialogConfirmGeneratePDFAssociado = true
          break
        case 'Send-Email-Declaracao-Cancelamento':
          this.onSendEmailDeclaracaoCancelamento()
          break
      }
    }
  }
}
</script>

<style>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
