<template>
  <!-- -->
  <v-row justify="center">
    <v-dialog
      v-model="fShowDialogSearch"
      max-width="60%"
      class="elevation-1"
      persistent
    >
      <v-card-actions id="frmSearch">
        <v-card width="100%">
          <v-card-title class="info white--text">
            Pesquisar
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-form>
              <!-- v-row>
                <v-col cols="12" sm="9" md="9">
                  <v-text-field
                    :label="fTitleName"
                    append-icon="mdi-magnify"
                    v-model="fFieldSearch"
                    type="text"
                  />
                </v-col>
              </v-row -->
              <v-row>
                <v-col col="6" sm="6" md="6">
                  <v-select
                    v-model="fFieldSearch"
                    :items="fFiltro"
                    menu-props="auto"
                    label="Status"
                    hide-details
                    item-value="value"
                    item-text="text"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions class="info white--text">
            <v-spacer />
            <v-btn color="white info--text" small @click="onGoSearchRecords">
              <v-icon>mdi-magnify</v-icon>Pesquisar
            </v-btn>
            <v-btn
              color="white info--text"
              small
              @click="onToggleShowDialogSearch"
            >
              <v-icon>mdi-backup-restore</v-icon>Voltar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-actions>
      <!-- -->
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'DialogSearch',

  props: {
    fFieldSearch: {
      type: String,
      default: '1'
    },
    fTitleName: String,
    fShowDialogSearch: Boolean
  },

  data() {
    return {
      fFiltro: [
        { text: 'ASSOCIADO', value: 1 },
        { text: 'CANCELAMENTO SOLICITADO', value: 2 },
        { text: 'CANCELAMENTO CONFIRMADO PELO CLIENTE', value: 3 },
        { text: 'CANCELAMENTO CONFIRMADO PELA ASSOCIAÇÃO', value: 4 }
      ]
    }
  },

  methods: {
    onGoSearchRecords() {
      this.$emit('onGoSearchRecordsOwner', this.fFieldSearch)
    },

    onToggleShowDialogSearch() {
      this.$emit('onToggleShowDialogSearchOwner')
    }
  }
}
</script>
