<template>
  <!-- FORM DIALOG -->
  <v-dialog
    v-model="fShowDialogData"
    max-width="60%"
    class="elevation-1"
    persistent
  >
    <v-card width="100%">
      <v-card-title class="info white--text">
        Usuário
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="fRecord.nm_nome"
                label="Nome"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="fRecord.nm_email"
                label="Email"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-text-field
                v-model="fRecord.cd_login"
                label="Login"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col col="6" sm="6" md="6">
              <v-select
                v-model="fRecord.id_tipousuario"
                :items="fTiposUsuario"
                menu-props="auto"
                label="Tipo Usuário"
                hide-details
                item-value="id_tipousuario"
                item-text="nm_descricao"
                @change="onDataChanged"
              ></v-select>
            </v-col>
            <v-col cols="4" sm="4" md="4">
              <v-checkbox
                v-model="fRecord.fl_ativo"
                false-value="F"
                true-value="T"
                class="mx-2"
                label="Ativo"
                @change="onDataChanged"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model="fPassWords.fPassWord"
                label="Senha"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
                :type="fShowPassword ? 'text' : 'password'"
                :append-icon="fShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="fShowPassword = !fShowPassword"
              />
            </v-col>
            <!-- :rules="[rules.required, rules.min, rules.passwordEquals]" -->
            <v-col cols="3" sm="3" md="3">
              <v-text-field
                v-model="fPassWords.fConfirmPassWord"
                label="Confirme sua senha"
                required
                @change="onDataChanged"
                @keydown="onDataChanged"
                :type="fShowPasswordConfirmeSenha ? 'text' : 'password'"
                :append-icon="
                  fShowPasswordConfirmeSenha ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="
                  fShowPasswordConfirmeSenha = !fShowPasswordConfirmeSenha
                "
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <ButtonsDialogData
        :fInInsert="fInInsert"
        :fInEdit="fInEdit"
        :fShowBtnCustom01="false"
        :fDataChanged="fDataChanged"
        @onEventBtnCustom01Owner="onGoItemUsuario"
        @onSaveRecordOwner="onSaveRecord"
        @onDeleteRecordOwner="onDeleteRecord"
        @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
        @onNewDataOwner="onNewData"
        @onCancelRecordChangedOwner="onCancelRecordChanged"
      ></ButtonsDialogData>
      <!-- /v-card-actions -->
    </v-card>
  </v-dialog>
</template>
<script>
import ButtonsDialogData from '@/components/ButtonsDialogData.vue'

export default {
  name: 'UsuarioDialogData',

  components: {
    ButtonsDialogData
  },

  data() {
    return {
      fShowPassword: false,
      fShowPasswordConfirmeSenha: false
    }
  },

  props: {
    fShowDialogData: Boolean,
    fRecord: Object,
    fTiposUsuario: Array,
    fInInsert: Boolean,
    fInEdit: Boolean,
    fDataChanged: {
      type: Boolean,
      default: false
    },
    fPassWords: Object
  },

  methods: {
    onNewData() {
      this.$store.dispatch('rollBackUsuario')
      this.$emit('onNewDataOwner')
    },

    onDataChanged() {
      this.$emit('onDataChangedOwner')
    },

    onCancelRecordChanged() {
      this.$emit('onCancelRecordChangedOwner')
    },

    onSaveRecord() {
      this.$emit('onSaveRecordOwner')
    },

    onDeleteRecord() {
      this.$emit('onDeleteRecordOwner')
    },

    onCancelAndBackToListData() {
      this.$emit('onCancelAndBackToListDataOwner')
    },

    onGoItemUsuario() {
      this.$emit('onGoItemUsuarioOwner')
    }
  }
}
</script>
