const APPVERSION = '1.00.20201125.1500'

const cNivelZeroMenu = '0'

const ADMAPP = 1
const ADMEMPRESA = 69
const GESTOREMPRESA = 75
const USEREMPRESA = 9

const ASPROFILI = 'ASPROFILI'
const ANEBRASIL = 'ANEBRASIL'

const cOriginAllRecords = 0
const cOriginPanelSearch = 1

const cShowConsolelog = true

const cTrueStrChar = 'T'
const cFalseStrChar = 'F'

const cCliente = ASPROFILI

var lClienteStr = ''
var lLogoSite = ''
var lURLSite = ''
var lHeaderColorBackGround = ''
var lFooterColorBackGround = ''
var lTextFooter = ''
var lMaxHeight = ''
var lMaxWidth = ''
var lTesteString = ''
var lBaseURL = ''
var lBaseURLCadastroOnline = ''
var lBaseURLDeclaracaoAssociado = ''

if (cCliente == ASPROFILI) {
  lClienteStr = 'ASPROFILI'
  lLogoSite =
    'https://isappsbackupbucket01.s3-sa-east-1.amazonaws.com/images/logo-asprofili.png'
  lURLSite = 'http://www.asprofili.com.br'
  lHeaderColorBackGround = '#0E5536'
  lFooterColorBackGround = '#222222'
  lTextFooter = 'Copyright 2020 Asprofili'
  lMaxHeight = '52'
  lMaxWidth = '163'
  lTesteString = ''
  lBaseURL = 'https://api.isapps.com.br/asprofili/api/v1/adm'
  lBaseURLCadastroOnline = 'https://api.isapps.com.br/asprofili/api/v1'
  lBaseURLDeclaracaoAssociado = 'https://api.isapps.com.br/asprofili/api/v1/dec'
  document.title = 'ASPROFILI - Associação dos Profissionais Liberais'
} else if (cCliente == ANEBRASIL) {
  lClienteStr = 'ANE BRASIL'
  lLogoSite =
    'https://isappsbackupbucket01.s3-sa-east-1.amazonaws.com/images/logo-ane-brasil-horizontal-1.png'
  lURLSite = 'http://www.anebrasil.com.br'
  lHeaderColorBackGround = '#FFFFFF'
  lFooterColorBackGround = '#222222'
  lTextFooter = '© 2020 ANE BRASIL'
  lMaxHeight = '80'
  lMaxWidth = '247'
  lTesteString = ''
  lBaseURL = 'https://api.isapps.com.br/anebrasil/api/v1/adm'
  lBaseURLCadastroOnline = 'https://api.isapps.com.br/anebrasil/api/v1'
  lBaseURLDeclaracaoAssociado = 'https://api.isapps.com.br/anebrasil/api/v1/dec'
  document.title = 'ANE BRASIL - ASSOCIAÇÃO NACIONAL DOS ESTUDANTES'
}

const cClienteStr = lClienteStr
const cLogoSite = lLogoSite
const cURLSite = lURLSite
const cHeaderColorBackGround = lHeaderColorBackGround
const cFooterColorBackGround = lFooterColorBackGround
const cTextFooter = lTextFooter
const cMaxHeight = lMaxHeight
const cMaxWidth = lMaxWidth
const cTesteString = lTesteString

// DEV - CONSOLE
//lBaseURL = 'http://localhost:9001/api/v1/adm'
//lBaseURLCadastroOnline = 'http://localhost:9000/api/v1'
//lBaseURLDeclaracaoAssociado = 'http://localhost:9000/api/v1'

const cBaseURL = lBaseURL
const cBaseURLCadastroOnline = lBaseURLCadastroOnline
const cBaseURLDeclaracaoAssociado = lBaseURLDeclaracaoAssociado

// ASPROFILI
/*
const cCliente = ASPROFILI
const cClienteStr = 'ASPROFILI'
const cLogoSite = '/img/logo-asprofili.png'
const cURLSite = 'http://www.asprofili.com.br'
const cHeaderColorBackGround = '#0E5536'
const cFooterColorBackGround = '#222222'
const cTextFooter = 'Copyright 2020 Asprofili'
const cMaxHeight = '52'
const cMaxWidth = '163'
const cBaseURL = 'https://api.isapps.com.br/asprofili/api/v1/adm'
const cBaseURLCadastroOnline = 'https://api.isapps.com.br/asprofili/api/v1'
const cBaseURLDeclaracaoAssociado =
  'https://api.isapps.com.br/asprofili/api/v1/dec'
/*
const cBaseURL = 'http://cadastroassociado.asprofili.com.br/api/v1/adm'
const cBaseURLCadastroOnline =
  'http://cadastroassociado.asprofili.com.br/api/v1'
const cBaseURLDeclaracaoAssociado =
  'http://cadastroassociado.asprofili.com.br/api/v1/dec'
*/
//const cTesteString = ''
//*/
// ASPROFILI - TESTE
/*
const cCliente = ASPROFILI
const cClienteStr = 'ASPROFILI'
const cLogoSite = '/img/logo-asprofili.png'
const cURLSite = 'http://www.asprofili.com.br'
const cHeaderColorBackGround = '#0E5536'
const cFooterColorBackGround = '#222222'
const cTextFooter = 'Copyright 2020 Asprofili'
const cMaxHeight = '52'
const cMaxWidth = '163'
const cBaseURL = 'http://cadastroassociado-teste.asprofili.com.br/api/v1/adm'
const cBaseURLCadastroOnline =
  'http://cadastroassociado-teste.asprofili.com.br/api/v1'
const cTesteString = 'ASPROFILI - TESTE - '
*/
// ANEBRASIL
/*
const cCliente = ANEBRASIL
const cClienteStr = 'ANE BRASIL'
const cLogoSite = '/img/logo-ane-brasil-horizontal-1.png'
const cURLSite = 'http://www.anebrasil.com.br'
const cHeaderColorBackGround = '#FFFFFF'
const cFooterColorBackGround = '#222222'
const cTextFooter = '© 2020 ANE BRASIL'
const cMaxHeight = '80'
const cMaxWidth = '247'
const cBaseURL = 'http://cadastroassociado.anebrasil.com.br/api/v1/adm'
const cBaseURLCadastroOnline = 'http://cadastroassociado.anebrasil.com.br/api/v1'
const cTesteString = ''
*/
const _getImageURL = () => {
  return cLogoSite
}

export {
  APPVERSION,
  ADMAPP,
  ADMEMPRESA,
  GESTOREMPRESA,
  USEREMPRESA,
  ASPROFILI,
  ANEBRASIL,
  cCliente,
  cClienteStr,
  cURLSite,
  cHeaderColorBackGround,
  cFooterColorBackGround,
  cTextFooter,
  cMaxHeight,
  cMaxWidth,
  cBaseURL,
  cBaseURLCadastroOnline,
  cBaseURLDeclaracaoAssociado,
  cTesteString,
  cNivelZeroMenu,
  _getImageURL,
  cOriginAllRecords,
  cOriginPanelSearch,
  cShowConsolelog,
  cTrueStrChar,
  cFalseStrChar
}
