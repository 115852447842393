export default {
  /*fDadosGraphic: {},
  fDadosGraphicClean: {},*/
  fDadosDashBoard: {
    fAssociadosHoje: 0,
    fAssociadosOntem: 0,
    fAssociadosMesAtual: 0,
    fAssociadosMesAnterior: 0,
    fAssociadosEfetivosHoje: 0,
    fAssociadosVinculadosHoje: 0,
    fAssociadosEfetivosOntem: 0,
    fAssociadosVinculadosOntem: 0,
    fAssociadosEfetivosMesAtual: 0,
    fAssociadosVinculadosMesAtual: 0,
    fAssociadosEfetivosMesAnterior: 0,
    fAssociadosVinculadosMesAnterior: 0,
    fHoje: '',
    fOntem: '',
    fMesAtual: '',
    fMesAnterior: '',
    fNow: ''
  },
  fDadosDashBoardClean: {
    fAssociadosHoje: 0,
    fAssociadosOntem: 0,
    fAssociadosMesAtual: 0,
    fAssociadosMesAnterior: 0,
    fAssociadosEfetivosHoje: 0,
    fAssociadosVinculadosHoje: 0,
    fAssociadosEfetivosOntem: 0,
    fAssociadosVinculadosOntem: 0,
    fAssociadosEfetivosMesAtual: 0,
    fAssociadosVinculadosMesAtual: 0,
    fAssociadosEfetivosMesAnterior: 0,
    fAssociadosVinculadosMesAnterior: 0,
    fHoje: '',
    fOntem: '',
    fMesAtual: '',
    fMesAnterior: '',
    fNow: ''
  }
}
