<template>
  <v-card class="elevation-12" width="95%" height="80%">
    <!-- TOOLBAR -->
    <TitleToolBar
      :fTitleForm="fTitleForm"
      :fHaveDialogSearch="fHaveDialogSearch"
      :fShowBtnBack="fShowBtnBack"
      :fShowButtons="fShowButtons"
      :fShowButtonInsert="false"
      @onNewDataOwner="onNewData"
      @onClearListOwner="onClearList"
      @onToggleShowDialogSearchOwner="onToggleShowDialogSearch"
      @onEventBackOwner="onEventBack"
    />

    <CadastroDialogSearch
      v-if="fShowDialogSearch"
      :fFieldSearch="fFieldsSearch"
      :fShowDialogSearch="fShowDialogSearch"
      fTitleName="Informe o Tipo de Usuário a Pesquisar"
      @onToggleShowDialogSearchOwner="onToggleShowDialogSearch"
      @onGoSearchRecordsOwner="onGoSearchRecords"
    />

    <!-- LIST DATA -->
    <ListData
      :fFilter="fFilter"
      :fHeaders="fHeaders"
      :fListOfRecords="fListOfRecords"
      :fOriginOfRecords="fOriginOfRecords"
      :fLoading="fLoading"
      @onGoDetailRecordOwner="onGoDetailRecord"
    />

    <!-- ShowMessage
      v-model="fShowSnackBar"
      :fShowSnackBar="fShowSnackBar"
      :fTimeout="5000"
      :fClassSnackBar="fClassSnackBar"
      :fTextSnackBar="fTextSnackBar"
    / -->
    <v-snackbar
      v-model="fShowSnackBar"
      :timeout="5000"
      :color="fClassSnackBar"
      >{{ fTextSnackBar }}</v-snackbar
    >

    <DialogConfirm
      v-if="fShowDialogConfirm"
      :fShowDialogConfirm="fShowDialogConfirm"
      :fModeDialogConfirm="fModeDialogConfirm"
      :fTextTitleDialog="fTextTitleDialog"
      :fTextDialog="fTextDialog"
      @onDoDeleteRecordOwner="onDoDeleteRecord"
      @onDoCancelAndBackToListDataOwner="onDoCancelAndBackToListData"
      @onCloseDialogConfirmOwner="onCloseDialogConfirm"
    />

    <!-- FORM DIALOG -->
    <CadastroDialogData
      v-model="fShowDialogData"
      :fShowDialogData="fShowDialogData"
      :fRecord="fRecord"
      :fInEdit="fInEdit"
      :fInInsert="fInInsert"
      :fDataChanged="fPropsDataChanged"
      @onDataChangedOwner="onDataChanged"
      @onSaveRecordOwner="onSaveRecord"
      @onDeleteRecordOwner="onDeleteRecord"
      @onCancelAndBackToListDataOwner="onCancelAndBackToListData"
      @onGoItemCadastroOwner="onGoItemCadastro"
      @onNewDataOwner="onNewData"
      @onCancelRecordChangedOwner="onCancelRecordChanged"
      @onSendLinkEmailAreaAssociadoOwner="onSendLinkEmailAreaAssociado"
      @onGeneratePDFAssociadoOwner="onGeneratePDFAssociado"
      @onSendEmailDeclaracaoCancelamentoOwner="
        onSendEmailDeclaracaoCancelamento
      "
    />
  </v-card>
</template>

<script>
import { apiCadastroOnline, apiDeclaracao } from '@/services/api'
import CadastroDialogData from './CadastroDialogData.vue'
import DialogConfirm from '@/components/DialogConfirm.vue'
import TitleToolBar from '@/components/TitleToolBar.vue'
import CadastroDialogSearch from './CadastroDialogSearch.vue'
import ListData from '@/components/ListData.vue'
import { mapActions, mapGetters } from 'vuex'
import {
  _onGoDetailRecord,
  //_onGoSearchRecords,
  _onDoDeleteRecord,
  _onGoSearchRecordsWithParams,
  _InsertRecord,
  _UpdateRecord
} from '@/services/functions/crud'
import {
  _ShowDialogData,
  _ShowErrorMessage,
  _ShowAlertMessage,
  _ShowMessageSnackBar,
  //_doRevalidateToken,
  _ShowDialogConfirm,
  _CloseDialogData,
  _CancelAndBackToListData,
  _onClearList,
  _doMountedView,
  _onNewData,
  _onCancelAndBackToListData,
  _onCloseDialogConfirm,
  _onSaveRecord,
  //_VerifyTokenExpired,
  _Logout,
  _onDoCancelAndBackToListData,
  _onToggleShowDialogSearch,
  _onDataChanged,
  _onDeleteRecord,
  //_OriginOfRecords,
  //_ConsoleLog,
  _onCancelRecordChanged
  //cOriginPanelSearch
} from '@/services/functions/view'
//import { _ConsoleLog } from '../../../services/functions/view'
import {
  cOriginAllRecords,
  cOriginPanelSearch,
  cFalseStrChar,
  cTrueStrChar
} from '@/services/consts'
//import { cTrueStrChar, cFalseStrChar, cClienteStr } from '@/services/consts'

export default {
  name: 'CadastroListData',

  components: {
    DialogConfirm,
    TitleToolBar,
    CadastroDialogSearch,
    ListData,
    CadastroDialogData
  },

  data() {
    return {
      fShowButtons: true,

      fShowDialogSearch: false,
      fHaveDialogSearch: true,

      fTextTitleDialog: String,
      fTextDialog: String,

      fTextSnackBar: '',
      fShowSnackBar: false,

      fInInsert: false,
      fInEdit: false,

      fDataChanged: false,

      fShowDialogConfirm: false,

      fModeDialogConfirm: 0,
      fModeDialogDelete: 1,
      fModeDialogCancelAndBack: 2,

      fShowDialogData: false,
      fClassSnackBar: 'info--text',

      fOriginOfRecords: cOriginAllRecords,

      fEmailLogin: { Email: '' },

      fFilter: '',
      //fFieldsSearch: { name: '' }
      fFieldsSearch: '1'
    }
  },

  computed: {
    ...mapGetters({
      fListOfRecords: 'CadastroList',
      fRecord: 'Cadastro',
      fRecordNotChanged: 'CadastroNotChanged',
      fIdUserLogged: 'IdUserLogged',
      fHeaders: 'CadastroHeaders',
      fLoading: 'CadastroLoading',
      fTypeUser: 'TypeUser'
    }),

    fShowBtnBack: function() {
      return false
    },

    fTitleForm: function() {
      return 'Associados Online'
    },

    fPropsDataChanged: function() {
      return this.fDataChanged
    }
  },

  mounted() {
    _doMountedView(this)
  },

  methods: {
    // -- MAPEAMENTOS DAS ACTIONS DO VUEX --
    ...mapActions({
      clearListOfRecords: 'clearCadastroList',
      clearRecord: 'clearCadastro'
    }),

    // -- EVENTOS --

    onClearList() {
      _onClearList(this)
    },

    onNewData() {
      _onNewData(this)
    },

    onCancelRecordChanged() {
      _onCancelRecordChanged(this, 'rollBackCadastro')
    },

    onCancelAndBackToListData() {
      _onCancelAndBackToListData(this)
    },

    onDoCancelAndBackToListData() {
      _onDoCancelAndBackToListData(this)
    },

    onSaveRecord() {
      _onSaveRecord(this)
    },

    onGoDetailRecord(aRecord) {
      _onGoDetailRecord(this, 'getCadastroByID', aRecord)
    },

    onGoSearchRecords(aFieldSearch) {
      this.fFieldsSearch = aFieldSearch
      this.fOriginOfRecords = cOriginPanelSearch
      alert('onGoSearchRecords(aFieldSearch): ' + aFieldSearch)
      _onGoSearchRecordsWithParams(this, 'searchCadastroByStatus', aFieldSearch)
    },

    onDataChanged() {
      _onDataChanged(this)
    },

    onDeleteRecord() {
      _onDeleteRecord(this)
    },

    onCloseDialogConfirm() {
      _onCloseDialogConfirm(this)
    },

    onDoDeleteRecord() {
      _onDoDeleteRecord(this, 'deleteCadastro', this.fRecord)
    },

    onToggleShowDialogSearch() {
      _onToggleShowDialogSearch(this)
    },

    onGoItemCadastro() {
      this.$store.dispatch('setIdOpcaoPaiRecordCurrent')
      this.onCancelAndBackToListData()
      this.onClearList()
    },

    onEventBack() {
      this.$store.dispatch('setIdOpcaoPaiBackRecord')
      this.onClearList()
      this.onGoDetailRecord(this.fRecord)
    },

    onSendLinkEmailAreaAssociado() {
      if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar) {
        this.ShowErrorMessage(
          'E-mail não pode ser enviado, Associado solicitou o cancelamento da Associação em ' +
            this.fRecord.dthr_confirmacaocancelamentostr,
          'white error--text'
        )
      } else {
        this.fEmailLogin.Email = this.fRecord.nm_email
        apiCadastroOnline
          .post('/emaillogin', this.fEmailLogin)
          .then(() => {
            this.ShowMessageSnackBar(
              'Verifique sua caixa de email, um email com o link para login acaba de ser enviado',
              'white info--text'
            )
          })
          .catch(lError => {
            this.ShowErrorMessage(
              lError.response.data.MsgErro,
              'white error--text'
            )
          })
      }
    },

    onGeneratePDFAssociado() {
      if (this.fRecord.fl_confirmacaocancelamento == cTrueStrChar) {
        this.ShowErrorMessage(
          'Declaração não pode ser gerada, Associado solicitou o cancelamento da Associação em ' +
            this.fRecord.dthr_confirmacaocancelamentostr,
          'white error--text'
        )
      } else {
        apiDeclaracao
          .get(`/declaracao/${this.fRecord.id_cadastro}`)
          .then(lResponse => {
            //this.ShowMsgAlert("Declaração Gerada. Por favor verifique se seu navegador esta permitindo janelas popup.", "info white--text");
            //window.open(`/pdf/${lResponse.data.PDFFileName}`)
            window.open(lResponse.data.URLDeclaracao)
            /*let pdfWindow = window.open('')
            pdfWindow.document.write(
              //"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
              "<iframe width='100%' height='100%' src='" +
                encodeURI(lResponse.data.DeclaracaoBase64) +
                "'></iframe>"
            )*/
          })
          .catch(() => {
            this.ShowErrorMessage(
              'Declaração do Associado não Gerada',
              'white error--text'
            )
          })
          .then(() => {
            this.fEnabledBtnImprimir = true
          })
      }
    },

    onSendEmailDeclaracaoCancelamento() {
      if (this.fRecord.fl_confirmacaocancelamento == cFalseStrChar) {
        this.ShowErrorMessage(
          'Declaração de Cancelamento não pode ser gerada, Associado ainda não confirmou o cancelamento da Associação',
          'white error--text'
        )
      } else {
        this.ShowMessageSnackBar(
          'Opção ainda não implementada',
          'white info--text'
        )
      }
    },

    // -- METODOS / FUN��ES --

    Logout() {
      _Logout(this)
    },

    ShowErrorMessage(aErrorMessage) {
      _ShowErrorMessage(this, aErrorMessage)
    },

    ShowAlertMessage(aAlertMessage) {
      _ShowAlertMessage(this, aAlertMessage)
    },

    SetDefaultValues(aRecordParam) {
      aRecordParam.id_usuariosistema = this.fIdUserLogged
      aRecordParam.dthr_reg = new Date().toISOString()
      return aRecordParam
    },

    InsertRecord(aRecord) {
      aRecord = this.SetDefaultValues(aRecord)
      _InsertRecord(this, 'insertCadastro', aRecord)
    },

    UpdateRecord(aRecord) {
      aRecord = this.SetDefaultValues(aRecord)
      _UpdateRecord(this, 'updateCadastro', aRecord)
    },

    ShowDialogConfirm(aTitle, aText, aDialogMode) {
      _ShowDialogConfirm(this, aTitle, aText, aDialogMode)
    },

    ShowDialogData() {
      _ShowDialogData(this)
    },

    CloseDialogData() {
      _CloseDialogData(this)
    },

    CancelAndBackToListData() {
      _CancelAndBackToListData(this)
    },

    ShowMessageSnackBar(aText, aTypeAlert) {
      _ShowMessageSnackBar(this, aText, aTypeAlert)
    }
  }
}
</script>
