<template>
  <v-container>
    <v-row>
      <div class="col pa-4 py-5 primary--text">
        <h1>DashBoard - Posição em {{ fNow }}</h1>
      </div>
    </v-row>

    <v-row>
      <div class="col pa-4 py-5 primary--text">
        <h4>
          <a href="#" @click="updateDashBoard()"
            >Próxima atualização as {{ fNextUpdate }}, clique aqui para
            atualizar agora</a
          >
        </h4>
      </div>
    </v-row>

    <v-row>
      <div class="col pa-4 py-5 primary--text">
        <h4>
          <a href="#" @click="openEvolucaoAssociacoes()"
            >Clique aqui para ver o gráfico da evolução das associações</a
          >
        </h4>
        <!--v-btn small class="primary--text" @click="updateDashBoard"
          ><v-icon>mdi-refresh</v-icon>Atualizar Agora</v-btn
        -->
      </div>
    </v-row>

    <v-row>
      <DashBoardCard
        fCols="4"
        fSM="6"
        fMD="6"
        fTitle="Total de Associados"
        :fTextValue="fDadosDashBoard.fAssociadosTotal"
        fClassDiv="col pa-4 py-5 info--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="6"
        fMD="6"
        :fTitle="fTitleAssociadosProjecaoTotalFimDoMes"
        :fTextValue="fDadosDashBoard.fAssociadosProjecaoTotalFimDoMes"
        fClassDiv="col pa-4 py-5 info--text"
      />
    </v-row>

    <v-row>
      <DashBoardCard
        fCols="4"
        fSM="6"
        fMD="6"
        fTitle="Associados Hoje"
        :fTextValue="fDadosDashBoard.fAssociadosHoje"
        :fTextExtraValue="fDadosDashBoard.fHoje"
        fClassDiv="col pa-4 py-5 primary--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="6"
        fMD="6"
        fTitle="Associados Ontem"
        :fTextValue="fDadosDashBoard.fAssociadosOntem"
        :fTextExtraValue="fDadosDashBoard.fOntem"
        fClassDiv="col pa-4 py-5 primary--text"
      />
    </v-row>

    <v-row>
      <DashBoardCard
        fCols="4"
        fSM="3"
        fMD="3"
        fTitle="Associados Efetivos Hoje"
        :fTextValue="fDadosDashBoard.fAssociadosEfetivosHoje"
        :fTextExtraValue="fDadosDashBoard.fHoje"
        fClassDiv="col pa-4 py-5 success--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="3"
        fMD="3"
        fTitle="Associados Vinculados Hoje"
        :fTextValue="fDadosDashBoard.fAssociadosVinculadosHoje"
        :fTextExtraValue="fDadosDashBoard.fHoje"
        fClassDiv="col pa-4 py-5 success--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="3"
        fMD="3"
        fTitle="Associados Efetivos Ontem"
        :fTextValue="fDadosDashBoard.fAssociadosEfetivosOntem"
        :fTextExtraValue="fDadosDashBoard.fOntem"
        fClassDiv="col pa-4 py-5 success--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="3"
        fMD="3"
        fTitle="Associados Vinculados Ontem"
        :fTextValue="fDadosDashBoard.fAssociadosVinculadosOntem"
        :fTextExtraValue="fDadosDashBoard.fOntem"
        fClassDiv="col pa-4 py-5 success--text"
      />
    </v-row>

    <v-row>
      <DashBoardCard
        fCols="4"
        fSM="4"
        fMD="4"
        fTitle="Associados Mês Atual"
        :fTextValue="fDadosDashBoard.fAssociadosMesAtual"
        :fTextExtraValue="fDadosDashBoard.fMesAtual"
        fClassDiv="col pa-4 py-5 primary--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="4"
        fMD="4"
        fTitle="Associados Mês Anterior"
        :fTextValue="fDadosDashBoard.fAssociadosMesAnterior"
        :fTextExtraValue="fDadosDashBoard.fMesAnterior"
        fClassDiv="col pa-4 py-5 primary--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="4"
        fMD="4"
        fTitle="Projeção Total de Associados Mês Atual"
        :fTextValue="fDadosDashBoard.fAssociadosProjecaoFimDoMes"
        :fTextExtraValue="fDadosDashBoard.fMesAtual"
        fClassDiv="col pa-4 py-5 primary--text"
      />
    </v-row>

    <v-row>
      <DashBoardCard
        fCols="4"
        fSM="3"
        fMD="3"
        fTitle="Associados Efetivos Mês Atual"
        :fTextValue="fDadosDashBoard.fAssociadosEfetivosMesAtual"
        :fTextExtraValue="fDadosDashBoard.fMesAtual"
        fClassDiv="col pa-4 py-5 success--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="3"
        fMD="3"
        fTitle="Associados Vinculados Mês Atual"
        :fTextValue="fDadosDashBoard.fAssociadosVinculadosMesAtual"
        :fTextExtraValue="fDadosDashBoard.fMesAtual"
        fClassDiv="col pa-4 py-5 success--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="3"
        fMD="3"
        fTitle="Associados Efetivos Mês Anterior"
        :fTextValue="fDadosDashBoard.fAssociadosEfetivosMesAnterior"
        :fTextExtraValue="fDadosDashBoard.fMesAnterior"
        fClassDiv="col pa-4 py-5 success--text"
      />
      <DashBoardCard
        fCols="4"
        fSM="3"
        fMD="3"
        fTitle="Associados Vinculados Mês Anterior"
        :fTextValue="fDadosDashBoard.fAssociadosVinculadosMesAnterior"
        :fTextExtraValue="fDadosDashBoard.fMesAnterior"
        fClassDiv="col pa-4 py-5 success--text"
      />
    </v-row>
    <!--v-row>
      <zingchart :data="fChartData"></zingchart>
    </v-row-->
  </v-container>
</template>

<script>
import DashBoardCard from '@/components/DashBoardCard.vue'
//import { mapActions, mapGetters } from 'vuex'
import { mapGetters } from 'vuex'
import { crono } from 'vue-crono'
//import { _ConsoleLog } from '../services/functions/view'
//import 'zingchart/es6'
//import zingchartVue from 'zingchart-vue'

export default {
  name: 'DashBoard',

  components: {
    DashBoardCard
    //zingchart: zingchartVue
  },

  mixins: [crono],

  data() {
    return {
      fNow: '',
      fNextUpdate: ''
    }
  },

  mounted() {
    //_ConsoleLog('CRON START')
    this.updateDashBoard()
    this.$cron.start('updateDashBoard')
    this.cronRunning = true
  },

  computed: {
    ...mapGetters({
      fDadosDashBoard: 'DadosDashBoard'
    }),
    fTitleAssociadosProjecaoTotalFimDoMes: function() {
      return (
        'Projeção Total de Associados para fim do mês ' +
        this.fDadosDashBoard.fMesAtual
      )
    }
  },

  methods: {
    openEvolucaoAssociacoes() {
      this.$router.push('/mainmenu/dashboardevolucaoassociados')
    },
    updateDashBoard() {
      this.$store.dispatch('updateDashBoard')

      let lNow = new Date()
      this.fNow = lNow.toLocaleString()

      let lNextUpdate = new Date()
      let More5Minutus = 5
      lNextUpdate.setMinutes(lNextUpdate.getMinutes() + More5Minutus)
      this.fNextUpdate = lNextUpdate.toLocaleString()
    }
  },

  destroyed() {
    this.$cron.stop('updateDashBoard')
    this.cronRunning = false
    //_ConsoleLog('CRON STOP')
  },

  cron: {
    time: 300000,
    method: 'updateDashBoard'
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
