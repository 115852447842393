import { render, staticRenderFns } from "./UsuarioListData.vue?vue&type=template&id=32d8cac8&"
import script from "./UsuarioListData.vue?vue&type=script&lang=js&"
export * from "./UsuarioListData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VCard,VSnackbar})
