<template>
  <div>
    <v-card-actions class="info white--text">
      <v-menu top :offset-y="true" v-if="fShowMenu" x-small>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="white info--text" small v-bind="attrs" v-on="on">
            <v-icon>{{ fIconBtnCustom01 }}</v-icon
            >{{ fCaptionMenu }}
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            dense
            v-for="(fMenuItem, fIndex) in fMenuItems"
            :key="fIndex"
            @click="onClickMenu(fMenuItem.Action)"
          >
            <!--v-list-item-title small color="info--text">
              <v-icon small color="info">{{ fMenuItem.Icone }}</v-icon
              >{{ fMenuItem.Title }}
            </v-list-item-title-->
            <!--v-list-item-action>
              <v-icon small color="blue">{{ fMenuItem.Icone }}</v-icon>
            </v-list-item-action-->
            <v-list-item-content>
              <v-list-item-title class="info--text">
                {{ fMenuItem.Title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        id="btnCustom01"
        small
        class="white info--text"
        @click="onEventBtnCustom01"
        v-if="fShowBtnCustom01 && !fDataChanged"
      >
        <v-icon>{{ fIconBtnCustom01 }}</v-icon> {{ fCaptionBtnCustom01 }}
      </v-btn>
      <v-btn
        id="btnCustom02"
        small
        class="white info--text"
        @click="onEventBtnCustom02"
        v-if="fShowBtnCustom02 && !fDataChanged"
      >
        <v-icon>{{ fIconBtnCustom02 }}</v-icon
        >{{ fCaptionBtnCustom02 }}
      </v-btn>
      <!-- -->
      <v-spacer></v-spacer>
      <v-btn
        small
        class="white info--text"
        @click="onNewData"
        v-if="fShowButtonNewData && fInEdit && !fDataChanged"
      >
        <v-icon>mdi-plus</v-icon>Novo
      </v-btn>
      <v-btn
        id="btnPost"
        small
        class="white info--text"
        @click="onSaveRecord"
        v-if="fShowButtonPost && (fDataChanged || fInInsert)"
      >
        <v-icon>mdi-content-save</v-icon> Gravar
      </v-btn>
      <v-btn
        id="btnCancel"
        small
        class="white info--text"
        @click="onCancelRecordChanged"
        v-if="fShowButtonCancel && (fDataChanged || fInInsert)"
      >
        <v-icon>mdi-cancel</v-icon> Cancelar
      </v-btn>
      <v-btn
        id="btnDelete"
        v-if="fShowButtonDelete && !fInInsert && fInEdit && !fDataChanged"
        small
        class="white info--text"
        @click="onDeleteRecord"
      >
        <v-icon>mdi-delete-forever</v-icon> Excluir
      </v-btn>
      <v-btn
        id="btnFechar"
        small
        class="white info--text"
        @click="onCancelAndBackToListData"
        v-if="!fInInsert && fInEdit && !fDataChanged"
      >
        <v-icon>mdi-window-close</v-icon>Fechar
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: 'ButtonsDialogData',

  /*data() {
    return {
      fMenuItems: [
        { Title: 'Reenviar E-mail Acesso Area Associado', Action: '' }
      ]
    }
  },*/

  props: {
    fDataChanged: Boolean,
    fInInsert: Boolean,
    fInEdit: Boolean,
    fShowBtnCustom01: Boolean,
    fIconBtnCustom01: String,
    fCaptionBtnCustom01: String,
    fShowBtnCustom02: Boolean,
    fIconBtnCustom02: String,
    fCaptionBtnCustom02: String,
    fShowButtonNewData: {
      type: Boolean,
      default: true
    },
    fShowButtonPost: {
      type: Boolean,
      default: true
    },
    fShowButtonDelete: {
      type: Boolean,
      default: true
    },
    fShowButtonCancel: {
      type: Boolean,
      default: true
    },
    fShowMenu: {
      type: Boolean,
      default: false
    },
    fCaptionMenu: {
      type: String,
      default: 'Opções'
    },
    fMenuItems: Array
  },

  methods: {
    onNewData() {
      this.$emit('onNewDataOwner')
    },

    onSaveRecord() {
      this.$emit('onSaveRecordOwner')
    },

    onCancelRecordChanged() {
      this.$emit('onCancelRecordChangedOwner')
    },

    onDeleteRecord() {
      this.$emit('onDeleteRecordOwner')
    },

    onCancelAndBackToListData() {
      this.$emit('onCancelAndBackToListDataOwner')
    },

    onEventBtnCustom01() {
      this.$emit('onEventBtnCustom01Owner')
    },

    onEventBtnCustom02() {
      this.$emit('onEventBtnCustom02Owner')
    },

    onClickMenu(aAction) {
      this.$emit('onClickMenuOwner', aAction)
    }
  }
}
</script>

<style scoped>
.v-list .v-list-item--active .v-icon {
  color: royalblue;
}
.a {
  text-decoration: none;
}
.a:hover {
  text-decoration: underline;
}
</style>
