//import { _ConsoleLog } from '@/services/functions/view'

export default {
  SET_DADOSDASHBOARD: (aState, aValue) => {
    //_ConsoleLog('SET_DADOSDASHBOARD')
    //_ConsoleLog('aState.fDadosDashBoard: ')
    //_ConsoleLog(aState.fDadosDashBoard)
    //_ConsoleLog(aValue)
    aState.fDadosDashBoard = { ...aValue }
  }
  /*SET_DADOSGRAPHIC: (aState, aValue) => {
    //_ConsoleLog('SET_DADOSDASHBOARD')
    //_ConsoleLog('aState.fDadosDashBoard: ')
    //_ConsoleLog(aState.fDadosDashBoard)
    //_ConsoleLog(aValue)
    aState.fDadosGraphic = { ...aValue }
  }*/
}
