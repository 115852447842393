import api from '@/services/api'

const cPathResource = '/cadastro'

export default {
  clearCadastroList(aContext) {
    var lURL = cPathResource
    return new Promise((resolve, reject) => {
      aContext.commit('SET_CADASTROLOADINGDATATABLE', true)
      api
        .get(`${lURL}`)
        .then(lResponse => {
          if (lResponse.status == 204) {
            aContext.commit('SET_CADASTROLIST', [])
          } else {
            aContext.commit('SET_CADASTROLIST', lResponse.data)
          }
          aContext.commit('SET_CADASTROLOADINGDATATABLE', false)
          resolve(true)
        })
        .catch(lError => {
          aContext.commit('SET_CADASTROLIST', [])
          aContext.commit('SET_CADASTROLOADINGDATATABLE', false)
          reject(lError)
        })
    })
  },

  clearCadastro(aContext) {
    aContext.commit('SET_CADASTRO', aContext.state.fCadastroClean)
  },

  getCadastroByID(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_cadastro
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_CADASTRO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  deleteCadastro(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_cadastro
    return new Promise((resolve, reject) => {
      api
        .delete(`${lURL}`)
        .then(() => {
          aContext.commit('CLEAR_CADASTRO')
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  insertCadastro(aContext, aRecord) {
    return new Promise((resolve, reject) => {
      api
        .post(cPathResource, aRecord)
        .then(lResponse => {
          aContext.commit('SET_CADASTRO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  updateCadastro(aContext, aRecord) {
    var lURL = cPathResource + '/' + aRecord.id_cadastro
    return new Promise((resolve, reject) => {
      api
        .put(`${lURL}`, aRecord)
        .then(lResponse => {
          aContext.commit('SET_CADASTRO', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  /*
  searchCadastroListByName(aContext, aQueryString) {
    var lURL = cPathResource + 'search' + aQueryString
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_CADASTROLIST', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },
  */
  searchCadastroByStatus(aContext, aQueryString) {
    alert('searchCadastroByStatus: ' + aQueryString)
    var lURL = cPathResource + '/status/' + aQueryString
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          aContext.commit('SET_CADASTROLIST', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          reject(lError)
        })
    })
  },

  rollBackCadastro(aContext) {
    aContext.commit('SET_CADASTRO', aContext.state.fCadastroNotChanged)
  }
}
