import api from '@/services/api'
import { _ConsoleLog } from '@/services/functions/view'

const cPathResource = '/dashboard'

export default {
  updateDashBoard(aContext) {
    //_ConsoleLog('UPDATE DASHBOARD')
    return new Promise((resolve, reject) => {
      api
        .get(cPathResource)
        .then(lResponse => {
          //_ConsoleLog(lResponse.data)
          //_ConsoleLog('CHAMANDO SET_DADOSDASHBOARD')
          aContext.commit('SET_DADOSDASHBOARD', lResponse.data)
          resolve(true)
        })
        .catch(lError => {
          aContext.commit(
            'SET_DADOSDASHBOARD',
            aContext.state.fDadosDashBoardClean
          )
          reject(lError)
        })
    })
  },
  updateChartDataAnoAtual() {
    //_ConsoleLog('UPDATE DASHBOARD')
    return new Promise((resolve, reject) => {
      api
        .get('/dashboard/evolucaoassociados')
        .then(lResponse => {
          //aContext.commit('SET_DADOSGRAPHIC', lResponse.data)
          resolve(lResponse.data)
        })
        .catch(lError => {
          //aContext.commit('SET_DADOSGRAPHIC', aContext.state.fDadosGraphicClean)
          reject(lError)
        })
    })
  },
  updateChartDataPorPeriodo(aContext, ADatasPeriodo) {
    _ConsoleLog('ADatasPeriodo: ' + ADatasPeriodo)
    var lURL =
      '/dashboard/evolucaoassociadosporperiodo/' +
      ADatasPeriodo.DtIni +
      '/' +
      ADatasPeriodo.DtFim
    _ConsoleLog('lURL: ' + lURL)
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          //aContext.commit('SET_DADOSGRAPHIC', lResponse.data)
          resolve(lResponse.data)
        })
        .catch(lError => {
          //aContext.commit('SET_DADOSGRAPHIC', aContext.state.fDadosGraphicClean)
          reject(lError)
        })
    })
  },
  updateChartDataPorPeriodoUF(aContext, ADatasPeriodo) {
    _ConsoleLog('ADatasPeriodo: ' + ADatasPeriodo)
    var lURL =
      '/dashboard/evolucaoassociadosporuf/' +
      ADatasPeriodo.DtIni +
      '/' +
      ADatasPeriodo.DtFim
    _ConsoleLog('lURL: ' + lURL)
    return new Promise((resolve, reject) => {
      api
        .get(`${lURL}`)
        .then(lResponse => {
          //aContext.commit('SET_DADOSGRAPHIC', lResponse.data)
          resolve(lResponse.data)
        })
        .catch(lError => {
          //aContext.commit('SET_DADOSGRAPHIC', aContext.state.fDadosGraphicClean)
          reject(lError)
        })
    })
  }
}
