//import api from '../../../services/api'
//import { _VerifyTokenExpired } from '../view'
//import { _ConsoleLog } from '@/services/functions/view'
import { cOriginPanelSearch } from '@/services/consts'

const _UpdateRecord = (aThis, aActionStoreUpdate, aRecord) => {
  //if (!_VerifyTokenExpired(aThis)) {
  aThis.$store
    .dispatch(aActionStoreUpdate, aRecord)
    .then(() => {
      aThis.fDataChanged = false
      aThis.ShowMessageSnackBar('Registro Alterado...')
      aThis.onClearList()
    })
    .catch(lError => {
      aThis.ShowErrorMessage(lError.response.data.ErrorMessage)
    })
  //}
}

const _InsertRecord = (aThis, aActionStoreInsert, aRecord) => {
  //if (!_VerifyTokenExpired(aThis)) {
  aThis.$store
    .dispatch(aActionStoreInsert, aRecord)
    .then(() => {
      aThis.onClearList()
      aThis.ShowMessageSnackBar('Registro Inserido...')
      aThis.fDataChanged = false
      aThis.fInInsert = false
      aThis.fInEdit = true
    })
    .catch(lError => {
      aThis.ShowErrorMessage(lError.response.data.ErrorMessage)
    })
  //}
}

const _onGoDetailRecord = (aThis, aActionStoreGetRecord, aId) => {
  aThis.$store
    .dispatch(aActionStoreGetRecord, aId)
    .then(() => {
      aThis.fInEdit = true
      aThis.ShowDialogData()
    })
    .catch(lError => {
      aThis.ShowErrorMessage(lError.response.data.ErrorMessage)
      aThis.onClearList()
    })
  //}
}

/* VERIFICAR AQUI
const _onGoSearchRecords = (aThis, aActionStoreGetRecord, aFieldsSearch) => {
*/
const _onGoSearchRecords = (aThis, aActionStoreGetRecord) => {
  var lQueryString

  lQueryString = ''
  /* VERIFICAR AQUI
  for (lKey in aFieldsSearch) {
    if (aFieldsSearch.hasOwnProperty(lKey)) {
      if (lQueryString == '') {
        lQueryString = '?' + lQueryString + lKey + '=' + aFieldsSearch[lKey]
      } else {
        lQueryString = '&' + lQueryString + lKey + '=' + aFieldsSearch[lKey]
      }
    }
  }
  */
  //if (!_VerifyTokenExpired(aThis)) {
  aThis.$store
    .dispatch(aActionStoreGetRecord, lQueryString)
    .then(() => {
      /* VERIFICAR AQUI
      aThis.fOriginOfRecords = cOriginPanelSearch
      */
      aThis.onToggleShowDialogSearch()
    })
    .catch(lError => {
      aThis.ShowErrorMessage(lError.response.data.ErrorMessage)
      aThis.onClearList()
    })
  //}
}

const _onGoSearchRecordsWithParams = (
  aThis,
  aActionStoreGetRecord,
  aFieldsSearch
) => {
  /* VERIFICAR AQUI
  var lQueryString

  lQueryString = ''
  for (lKey in aFieldsSearch) {
    if (aFieldsSearch.hasOwnProperty(lKey)) {
      if (lQueryString == '') {
        lQueryString = '?' + lQueryString + lKey + '=' + aFieldsSearch[lKey]
      } else {
        lQueryString = '&' + lQueryString + lKey + '=' + aFieldsSearch[lKey]
      }
    }
  }
  */
  //if (!_VerifyTokenExpired(aThis)) {
  aThis.$store
    .dispatch(aActionStoreGetRecord, aFieldsSearch)
    .then(() => {
      aThis.fOriginOfRecords = cOriginPanelSearch
      aThis.onToggleShowDialogSearch()
    })
    .catch(lError => {
      aThis.ShowErrorMessage(lError.response.data.ErrorMessage)
      aThis.onClearList()
    })
  //}
}

const _onDoDeleteRecord = (aThis, aActionStoreDelete) => {
  //if (!_VerifyTokenExpired(aThis)) {
  aThis.$store
    .dispatch(aActionStoreDelete, aThis.fRecord)
    .then(lUpdateSuccess => {
      if (lUpdateSuccess) {
        aThis.onClearList()
        aThis.ShowMessageSnackBar('Registro Deletado...')
        aThis.onCloseDialogConfirm()
        aThis.CloseDialogData()
      } else {
        aThis.ShowMessageSnackBar('Registro Deletado...')
      }
    })
    .catch(lError => {
      aThis.ShowErrorMessage(lError.response.data.ErrorMessage)
    })
  //}
}

const _getTyperUser = aThis => {
  return aThis.$store.dispatch('getTyperUser')
}

export {
  _onDoDeleteRecord,
  _onGoDetailRecord,
  _InsertRecord,
  _UpdateRecord,
  _onGoSearchRecords,
  _onGoSearchRecordsWithParams,
  _getTyperUser
}
