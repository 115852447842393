//import { ADM } from '@/services/consts'
//import { _ConsoleLog } from '@/services/functions/view'

export default {
  DadosDashBoard: aState => {
    return aState.fDadosDashBoard
  }
  /*DadosGraphic: aState => {
    return aState.fDadosGraphic
  }*/
}
