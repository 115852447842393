<template>
  <v-container>
    <zingchart ref="myChart" :data="chartConfig"> </zingchart>
    <!--input type="text" v-model="values" /-->
  </v-container>
</template>

<script>
import zingchartVue from 'zingchart-vue'
//import { mapGetters } from 'vuex'
import { _ConsoleLog } from '../services/functions/view'
//import api from '@/services/api'

export default {
  components: {
    zingchart: zingchartVue
  },

  data() {
    return {
      fSeriesDatas: [],
      fSeriesAssociados: [],
      fSeriesEfetivos: [],
      fSeriesVinculados: [],
      values: [1, 4, 6, 3, 5, 6]
    }
  },

  computed: {
    /*...mapGetters({
      fDadosGraphic: 'DadosGraphic'
    }),*/
    chartConfig() {
      _ConsoleLog('DadosGraphic')
      _ConsoleLog(this.fDadosGraphic)
      return {
        type: 'line',
        legend: {},
        series: [
          { values: this.fSeriesAssociados },
          { values: this.fSeriesEfetivos },
          { values: this.fSeriesVinculados }
        ]
      }
      /*return {
        type: 'line',
        utc: true,
        title: {
          text: 'Evolução das Associações',
          'font-size': '24px',
          'adjust-layout': true,
          'font-color': 'info'
        },
        plotarea: {
          margin: 'dynamic 45 60 dynamic'
        },
        legend: {
          layout: 'float',
          'background-color': 'none',
          'border-width': 0,
          shadow: 0,
          align: 'center',
          'adjust-layout': true,
          'toggle-action': 'remove',
          item: {
            padding: 7,
            marginRight: 17,
            cursor: 'hand'
          }
        },
        'scale-x': {
          labels: this.fSeriesDatas
        },
        'scale-y': {
          'line-color': '#f6f7f8',
          shadow: 0,
          guide: {
            'line-style': 'dashed'
          },
          label: {
            text: 'Quantidade de Associados'
          },
          'minor-ticks': 0,
          'thousands-separator': ','
        },
        'crosshair-x': {
          'line-color': '#efefef',
          'plot-label': {
            'border-radius': '5px',
            'border-width': '1px',
            'border-color': '#f6f7f8',
            padding: '10px',
            'font-weight': 'bold'
          },
          'scale-label': {
            'font-color': '#000',
            'background-color': '#f6f7f8',
            'border-radius': '5px'
          }
        },
        tooltip: {
          visible: false
        },
        plot: {
          highlight: true,
          'tooltip-text': '%t views: %v<br>%k',
          shadow: 0,
          'line-width': '2px',
          marker: {
            type: 'circle',
            size: 3
          },
          'highlight-state': {
            'line-width': 3
          },
          animation: {
            effect: 1,
            sequence: 2,
            speed: 100
          }
        },
        series: [
          {
            values: this.fSeriesAssociados,
            text: 'Total Associados',
            'line-color': '#0D47A1',
            'legend-item': {
              'background-color': '#0D47A1',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#0D47A1',
              'border-width': 1,
              shadow: 0,
              'border-color': '#69dbf1'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#0D47A1' // 007790
            }
          },
          {
            values: this.fSeriesEfetivos,
            text: 'Associados Efetivos',
            'line-color': '#009872',
            'legend-item': {
              'background-color': '#009872',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#009872',
              'border-width': 1,
              shadow: 0,
              'border-color': '#69f2d0'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#009872'
            }
          },
          {
            values: this.fSeriesVinculados,
            text: 'Associados Vinculados',
            'line-color': '#da534d',
            'legend-item': {
              'background-color': '#da534d',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#da534d',
              'border-width': 1,
              shadow: 0,
              'border-color': '#faa39f'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#da534d'
            }
          }
        ]
      }*/
    }
  },

  methods: {
    _updateGraphic() {
      this.$store
        .dispatch('updateGraphic')
        .then(lResponse => {
          /*this.fSeriesDatas = [...lResponse.Datas]
          this.fSeriesAssociados = [...lResponse.Associados]
          this.fSeriesEfetivos = [...lResponse.Efetivos]
          this.fSeriesVinculados = [...lResponse.Vinculados]*/
          //this.fSeriesDatas = [...lResponse.Datas]
          this.fSeriesAssociados = [...lResponse.Associados]
          this.fSeriesEfetivos = [...lResponse.Efetivos]
          this.fSeriesVinculados = [...lResponse.Vinculados]
          this.values = lResponse.Associados
        })
        .catch(lError => {
          _ConsoleLog(lError)
        })
    }
  },

  mounted() {
    this._updateGraphic()
  }
}
</script>
