<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card width="75%">
        <v-card-title class="info white--text">
          Evolução das Associações
        </v-card-title>
        <v-card-text class="info--text">
          <v-row>
            <!--div class="col pa-4 py-5 primary--text">
              <h1>Evolução das Associações</h1>
            </div-->
            <!--v-col cols="4" sm="9" md="10">
              <h1>Pesquisar</h1>
            </v-col-->
          </v-row>
          <v-divider />
          <v-row>
            <v-col cols="9" sm="2" md="2">
              <v-select
                label="Período"
                v-model="fPeriodo"
                :items="fOpcoesPeriodo"
                menu-props="auto"
                hide-details
                item-value="value"
                item-text="text"
              />
            </v-col>
            <v-col cols="4" sm="3" md="3">
              <v-btn
                small
                class="blue lighten-5 info--text"
                @click="onUpdateDataDashBoard"
              >
                <v-icon>mdi-refresh</v-icon>Atualizar
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="fPeriodo == 2">
            <v-col cols="4" sm="2" md="2"
              ><DatePickerWithCalendar
                :fDate="fDatasPeriodo.DtIni"
                fLabel="Data Inicial"
                @onUpdateDateOwner="onUpdateDtIni"
              />
            </v-col>
            <v-col cols="4" sm="2" md="2"
              ><DatePickerWithCalendar
                :fDate="fDatasPeriodo.DtFim"
                fLabel="Data Final"
                @onUpdateDateOwner="onUpdateDtFim"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-text>
          <zingchart :data="fChartDataEvolucaoAssociados"></zingchart>
        </v-card-text>
        <v-card-text>
          <zingchart :data="fChartDataEvolucaoAssociadosPorUF"></zingchart>
        </v-card-text>
        <!--v-card-text>
          <v-row class="no-gutters">
            <div class="col-auto">
              <div class="cyan fill-height">&nbsp;</div>
            </div>
            <div class="col pa-4 py-5 info--text">
              <h5 class="text-truncate text-uppercase">Total de Associados</h5>
              <h1>{{ fDadosDashBoard.fAssociadosTotal }}</h1>
            </div>
          </v-row>
        </v-card-text-->
        <v-card-text>
          <v-col cols="4" sm="9" md="10">
            <h4>
              <a href="#" @click="_BackToDashBoard()"
                >Voltar para o DashBoard</a
              >
            </h4>
          </v-col>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import 'zingchart/es6'
import zingchartVue from 'zingchart-vue'
import { _ConsoleLog } from '@/services/functions/view'
import { mapGetters } from 'vuex'
import DatePickerWithCalendar from '@/components/DatePickerWithCalendar'

const cAnoAtual = 1
const cPorDatas = 2

export default {
  name: 'DashBoardEvolucaoAssociados',

  components: {
    zingchart: zingchartVue,
    DatePickerWithCalendar
  },

  data() {
    return {
      fDatasPeriodo: { DtIni: '', DtFim: '' },
      //fDtIni: '',
      //fDtFim: '',
      fMenu: false,
      fTitleChart: 'Associações Ano Atual',
      fTitleChartPorUF: 'Associações Por UF no Ano Atual',
      fLabelsUF: [],
      fAssociadosUF: [],
      fEfetivosUF: [],
      fVinculadosUF: [],
      fLabels: [],
      fAssociados: [],
      fEfetivos: [],
      fVinculados: [],
      fPeriodo: 1,
      fOpcoesPeriodo: [
        { text: 'Ano Atual', value: cAnoAtual },
        { text: 'Por Datas', value: cPorDatas }
      ]
    }
  },

  computed: {
    ...mapGetters({
      fDadosDashBoard: 'DadosDashBoard'
    }),
    fDtIni() {
      return (
        this.fDatasPeriodo.DtIni.substring(0, 2) +
        '/' +
        this.fDatasPeriodo.DtIni.substring(2, 4) +
        '/' +
        this.fDatasPeriodo.DtIni.substring(4)
      )
    },
    fDtFim() {
      return (
        this.fDatasPeriodo.DtFim.substring(0, 2) +
        '/' +
        this.fDatasPeriodo.DtFim.substring(2, 4) +
        '/' +
        this.fDatasPeriodo.DtFim.substring(4)
      )
    },
    fChartDataEvolucaoAssociados() {
      return {
        type: 'line',
        utc: true,
        title: {
          text: this.fTitleChart,
          'font-size': '40px',
          'adjust-layout': true,
          'font-color': '#1976D2',
          'font-weight': 'bold',
          'font-family': 'Arial'
        },
        xsubtitle: {
          text: this.fSubTitleChart,
          'font-size': '20px',
          'adjust-layout': true,
          'font-color': '#1976D2',
          'font-weight': 'bold',
          'font-family': 'Arial'
        },
        plotarea: {
          margin: 'dynamic 45 60 dynamic'
        },
        legend: {
          layout: 'float',
          'background-color': 'none',
          'border-width': 0,
          shadow: 0,
          align: 'center',
          'adjust-layout': true,
          'toggle-action': 'remove',
          item: {
            padding: 7,
            marginRight: 17,
            cursor: 'hand'
          }
        },
        'scale-x': {
          labels: this.fLabels
        },
        'scale-y': {
          'line-color': '#f6f7f8',
          shadow: 0,
          guide: {
            'line-style': 'dashed'
          },
          label: {
            text: 'Quantidade de Associados'
          },
          'minor-ticks': 0,
          'thousands-separator': ','
        },
        'crosshair-x': {
          'line-color': '#efefef',
          'plot-label': {
            'border-radius': '5px',
            'border-width': '1px',
            'border-color': '#f6f7f8',
            padding: '10px',
            'font-weight': 'bold'
          },
          'scale-label': {
            'font-color': '#000',
            'background-color': '#f6f7f8',
            'border-radius': '5px'
          }
        },
        tooltip: {
          visible: false
        },
        plot: {
          highlight: true,
          'tooltip-text': '%t views: %v<br>%k',
          shadow: 0,
          'line-width': '2px',
          marker: {
            type: 'circle',
            size: 3
          },
          'highlight-state': {
            'line-width': 3
          },
          animation: {
            effect: 1,
            sequence: 2,
            speed: 100
          }
        },
        series: [
          {
            values: this.fAssociados,
            text: 'Total Associados',
            'line-color': '#0D47A1',
            'legend-item': {
              'background-color': '#0D47A1',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#0D47A1',
              'border-width': 1,
              shadow: 0,
              'border-color': '#69dbf1'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#0D47A1' // 007790
            }
          },
          {
            values: this.fEfetivos,
            text: 'Associados Efetivos',
            'line-color': '#009872',
            'legend-item': {
              'background-color': '#009872',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#009872',
              'border-width': 1,
              shadow: 0,
              'border-color': '#69f2d0'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#009872'
            }
          },
          {
            values: this.fVinculados,
            text: 'Associados Vinculados',
            'line-color': '#da534d',
            'legend-item': {
              'background-color': '#da534d',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#da534d',
              'border-width': 1,
              shadow: 0,
              'border-color': '#faa39f'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#da534d'
            }
          }
        ]
      }
    },
    fChartDataEvolucaoAssociadosPorUF() {
      return {
        type: 'bar',
        utc: true,
        title: {
          text: this.fTitleChartPorUF,
          'font-size': '40px',
          'adjust-layout': true,
          'font-color': '#1976D2',
          'font-weight': 'bold',
          'font-family': 'Arial'
        },
        xsubtitle: {
          text: this.fSubTitleChart,
          'font-size': '20px',
          'adjust-layout': true,
          'font-color': '#1976D2',
          'font-weight': 'bold',
          'font-family': 'Arial'
        },
        plotarea: {
          margin: 'dynamic 45 60 dynamic'
        },
        legend: {
          layout: 'float',
          'background-color': 'none',
          'border-width': 0,
          shadow: 0,
          align: 'center',
          'adjust-layout': true,
          'toggle-action': 'remove',
          item: {
            padding: 7,
            marginRight: 17,
            cursor: 'hand'
          }
        },
        'scale-x': {
          labels: this.fLabelsUF
        },
        'scale-y': {
          'line-color': '#f6f7f8',
          shadow: 0,
          guide: {
            'line-style': 'dashed'
          },
          label: {
            text: 'Quantidade de Associados'
          },
          'minor-ticks': 0,
          'thousands-separator': ','
        },
        'crosshair-x': {
          'line-color': '#efefef',
          'plot-label': {
            'border-radius': '5px',
            'border-width': '1px',
            'border-color': '#f6f7f8',
            padding: '10px',
            'font-weight': 'bold'
          },
          'scale-label': {
            'font-color': '#000',
            'background-color': '#f6f7f8',
            'border-radius': '5px'
          }
        },
        tooltip: {
          visible: false
        },
        plot: {
          highlight: true,
          'tooltip-text': '%t views: %v<br>%k',
          shadow: 0,
          'line-width': '2px',
          marker: {
            type: 'circle',
            size: 3
          },
          'highlight-state': {
            'line-width': 3
          },
          animation: {
            effect: 1,
            sequence: 2,
            speed: 100
          }
        },
        series: [
          {
            values: this.fAssociadosUF,
            text: 'Total Associados',
            'line-color': '#0D47A1',
            'legend-item': {
              'background-color': '#0D47A1',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#0D47A1',
              'border-width': 1,
              shadow: 0,
              'border-color': '#69dbf1'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#0D47A1' // 007790
            }
          },
          {
            values: this.fEfetivosUF,
            text: 'Associados Efetivos',
            'line-color': '#009872',
            'legend-item': {
              'background-color': '#009872',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#009872',
              'border-width': 1,
              shadow: 0,
              'border-color': '#69f2d0'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#009872'
            }
          },
          {
            values: this.fVinculadosUF,
            text: 'Associados Vinculados',
            'line-color': '#da534d',
            'legend-item': {
              'background-color': '#da534d',
              borderRadius: 5,
              'font-color': 'white'
            },
            'legend-marker': {
              visible: false
            },
            marker: {
              'background-color': '#da534d',
              'border-width': 1,
              shadow: 0,
              'border-color': '#faa39f'
            },
            'highlight-marker': {
              size: 6,
              'background-color': '#da534d'
            }
          }
        ]
      }
    }
  },

  mounted() {
    this._UpdateChartDataAnoAtual()
  },

  methods: {
    _BackToDashBoard() {
      this.$router.push('/mainmenu/dashboard')
    },

    _UpdateChartDataAnoAtual() {
      this.$store.dispatch('updateDashBoard')
      this.$store
        .dispatch('updateChartDataAnoAtual')
        .then(lResponse => {
          this.fLabels = [...lResponse.Meses]
          this.fAssociados = [...lResponse.Associados]
          this.fEfetivos = [...lResponse.Efetivos]
          this.fVinculados = [...lResponse.Vinculados]
        })
        .catch(lError => {
          _ConsoleLog(lError)
        })
      var lDtIni = new Date()
      let ADatasPeriodo = {
        DtIni: '0101' + lDtIni.getFullYear(),
        DtFim: '3112' + lDtIni.getFullYear()
      }
      _ConsoleLog('_UpdateChartDataAnoAtual - DtIni: ' + ADatasPeriodo.fDtIni)
      _ConsoleLog('_UpdateChartDataAnoAtual - DtFim: ' + ADatasPeriodo.fDtFim)
      this._UpdateChartDataPorUF(ADatasPeriodo)
    },

    _UpdateChartDataPorDatas(ADatasPeriodo) {
      _ConsoleLog('_UpdateChartDataPorDatas - DtIni: ' + ADatasPeriodo.fDtIni)
      _ConsoleLog('_UpdateChartDataPorDatas - DtFim: ' + ADatasPeriodo.fDtFim)
      this.$store.dispatch('updateDashBoard')
      this.$store
        .dispatch('updateChartDataPorPeriodo', ADatasPeriodo)
        .then(lResponse => {
          this.fLabels = [...lResponse.Datas]
          this.fAssociados = [...lResponse.Associados]
          this.fEfetivos = [...lResponse.Efetivos]
          this.fVinculados = [...lResponse.Vinculados]
        })
        .catch(lError => {
          _ConsoleLog(lError)
        })
      this._UpdateChartDataPorUF(ADatasPeriodo)
    },

    _UpdateChartDataPorUF(ADatasPeriodo) {
      _ConsoleLog('_UpdateChartDataPorUF - DtIni: ' + ADatasPeriodo.fDtIni)
      _ConsoleLog('_UpdateChartDataPorUF - DtFim: ' + ADatasPeriodo.fDtFim)
      this.$store
        .dispatch('updateChartDataPorPeriodoUF', ADatasPeriodo)
        .then(lResponse => {
          this.fLabelsUF = [...lResponse.UFs]
          this.fAssociadosUF = [...lResponse.Associados]
          this.fEfetivosUF = [...lResponse.Efetivos]
          this.fVinculadosUF = [...lResponse.Vinculados]
        })
        .catch(lError => {
          _ConsoleLog(lError)
        })
    },

    onUpdateDtIni(aDate) {
      _ConsoleLog('onUpdateDtIni: ' + aDate)
      this.fDatasPeriodo.DtIni = aDate
      _ConsoleLog('onUpdateDtIni (DtIni): ' + this.fDatasPeriodo.DtIni)
    },

    onUpdateDtFim(aDate) {
      _ConsoleLog('onUpdateDtFim: ' + aDate)
      this.fDatasPeriodo.DtFim = aDate
      _ConsoleLog('onUpdateDtFim (DtFim): ' + this.fDatasPeriodo.fDtFim)
    },

    onUpdateDataDashBoard() {
      if (this.fPeriodo == cAnoAtual) {
        this.fTitleChart = 'Associações Ano Atual'
        this.fSubTitleChart = ''
        this._UpdateChartDataAnoAtual()
      } else {
        this.fTitleChart =
          'Associações no Período: ' + this.fDtIni + ' a ' + this.fDtFim
        this.fTitleChartPorUF =
          'Associações Por UF no Período ' + this.fDtIni + ' a ' + this.fDtFim
        this.fSubTitleChart =
          'Data Inicial: ' + this.fDtIni + ' a ' + this.fDtFim
        this._UpdateChartDataPorDatas(this.fDatasPeriodo)
      }
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
